import React, { useEffect, useRef, useState } from 'react';
import Carousel, { generateDots } from 'my-react-carousel';
import Image from '@jetshop/ui/Image';
import Styled from './Style';
import NonLinkCTA from '../../components/ui/NonLinkCTA';
import BannerLink from '../../components/ui/BannerLink';
import useChildren from '../../hooks/useChildren';
import { Below } from '@jetshop/ui/Breakpoints';

const Dot = ({ isActive, onClick }) => (
  <Styled.DotButton onClick={onClick} isActive={isActive} />
);

const HeroBlock = props => {
  const cleanedProps = useChildren(props);
  const heroItems = cleanedProps['heroItem'];
  const carousel = useRef(null);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (heroItems.length > 1) {
      const newIntervalId = setInterval(() => {
        if (carousel?.current?.next) {
          carousel.current.next();
        }
      }, 5000);
      setIntervalId(newIntervalId);
    }

    return () => {
      clearTimeout(intervalId);
    };
  }, [heroItems.length]);

  if (!heroItems) return null;

  const clearCarousel = () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };
  return (
    <Styled.HeroWrapper
      aria-label="hero carousel"
      onMouseEnter={() => clearCarousel()}
      rounded={cleanedProps.roundedCorners}
    >
      <Carousel
        ref={carousel}
        transitionDuration={500}
        slidesToShow={1}
        render={generateDots(({ slides, dots }) => (
          <>
            {slides}
            {heroItems.length > 1 && (
              <Styled.DotContainer>
                {dots.map(({ index, isActive, onClick }) => (
                  <Dot key={index} isActive={isActive} onClick={onClick} />
                ))}
              </Styled.DotContainer>
            )}
          </>
        ))}
      >
        {heroItems.map((heroItem, index) => {
          const textColour = heroItem.textcolour ?? '#ffffff';
          return (
            <Styled.HeroItemWrapper key={heroItem.key}>
              <Below breakpoint="sm">
                {matches =>
                  matches ? (
                    <Image
                      className="below-sm"
                      src={heroItem.backgroundImage}
                      aspect="5:4"
                      crop={true}
                      cover
                      sizes={[1, 1, 1, 1, 1, 1368]}
                    />
                  ) : (
                    <Image
                      className="above-sm"
                      src={heroItem.backgroundImage}
                      aspect="115:43"
                      crop={true}
                      cover
                      sizes={[1, 1, 1, 1, 1, 1368]}
                    />
                  )
                }
              </Below>
              {heroItem.removeImageBlur === false && (
                <Styled.FrostedGlass>
                  <div />
                </Styled.FrostedGlass>
              )}
              {(heroItem.externalLinkUrl || heroItem.internalLinkUrl) && (
                <BannerLink
                  externalLink={heroItem.externalLinkUrl}
                  internalLink={heroItem.internalLinkUrl}
                >
                  <Styled.ContentWrapper textColour={textColour}>
                    {heroItem.preHeader && (
                      <Styled.PreHeader level={4}>
                        {heroItem.preHeader}
                      </Styled.PreHeader>
                    )}
                    {heroItem.header && (
                      <Styled.Header level={1}>{heroItem.header}</Styled.Header>
                    )}
                    {heroItem.ctaLabel && (
                      <NonLinkCTA>{heroItem.ctaLabel}</NonLinkCTA>
                    )}
                  </Styled.ContentWrapper>
                </BannerLink>
              )}
              {!heroItem.externalLinkUrl && !heroItem.internalLinkUrl && (
                <Styled.ContentWrapper textColour={textColour}>
                  {heroItem.preHeader && (
                    <Styled.PreHeader level={4}>
                      {heroItem.preHeader}
                    </Styled.PreHeader>
                  )}
                  {heroItem.header && (
                    <Styled.Header level={1}>{heroItem.header}</Styled.Header>
                  )}
                  {heroItem.ctaLabel && (
                    <NonLinkCTA>{heroItem.ctaLabel}</NonLinkCTA>
                  )}
                </Styled.ContentWrapper>
              )}
            </Styled.HeroItemWrapper>
          );
        })}
      </Carousel>
    </Styled.HeroWrapper>
  );
};

export default HeroBlock;
