import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import CategoryHeader from './CategoryHeader';
import MaxWidth from '../../components/Layout/MaxWidth';
import CategoryHeaderLoadingState from './CategoryHeaderLoadingState';
import Filters from './Filters';

import routeQuery from '../../gql/queries/RouteQuery.gql';
import useInfinitePagination from '@jetshop/core/hooks/useInfinitePagination';
import { useLocation } from 'react-router';
import { TrendLink } from '../../components/ui/Button';
import qs from 'qs';
import { theme } from '../../components/Theme';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import ProductGrid from '../../components/ProductGrid';
import CategoryProducts from '../../blocks/CategoryProducts/CategoryProducts';
import CategoryProductsSmall from '../../blocks/CategoryProductsSmall/CategoryProductsSmall';
import ContentBlock from '../../blocks/ContentBlock/ContentBlock';
import ContentBlockV2 from '../../blocks/ContentBlockV2/ContentBlockV2';
import InfoBlock from '../../blocks/InfoBlock/InfoBlock';
import ImageBlock from '../../blocks/ImageBlock/ImageBlock';
import CategoryBlock from '../../blocks/CategoryBlock/CategoryBlock';
import CategoryIconBlock from '../../blocks/CategoryIconBlock/CategoryIconBlock';
import CategoryButtons from '../../blocks/CategoryButtons/CategoryButtons';
import IconsBlock from '../../blocks/IconsBlock/IconsBlock';
import HelloRetailProductList from '../../blocks/HelloRetail/HelloRetailProductList.js';
import HelloRetailProductListSmall from '../../blocks/HelloRetail/HelloRetailProductListSmall.js';
import ArticleBlock from '../../blocks/ArticleBlock/ArticleBlock';
import InstagramGallery from '../../blocks/InstagramGallery/InstagramGallery';
import ColouredContentBlock from '../../blocks/ColouredContentBlock/ColouredContentBlock';
import ColouredQuoteBlock from '../../blocks/ColouredQuoteBlock/ColouredQuoteBlock';
import HeroBlock from '../../blocks/HeroBlock/HeroBlock';
import HeroVideoBlock from '../../blocks/HeroVideoBlock/HeroVideoBlock';

const PageComponents = {
  CONTENTBLOCK: ContentBlock,
  CONTENTBLOCKV2: ContentBlockV2,
  CONTENTBLOCKITEM: 'contentBlockItem',
  CONTENTBLOCKITEMV2: 'contentBlockItemV2',
  PRODUCTLISTING: CategoryProducts,
  PRODUCTLISTINGSMALL: CategoryProductsSmall,
  CATEGORYBLOCK: CategoryBlock,
  CATEGORYICONBLOCK: CategoryIconBlock,
  CATEGORYICONITEM: 'categoryIconItem',
  ICONSBLOCK: IconsBlock,
  CATEGORYITEM: 'categoryItem',
  ICONSITEM: 'iconsItem',
  INFOBLOCKHTML: InfoBlock,
  COLOUREDCONTENTBLOCK: ColouredContentBlock,
  COLOUREDQUOTEBLOCK: ColouredQuoteBlock,
  ARTICLEBLOCK: ArticleBlock,
  ARTICLEINGRESSA: 'articleIngressA',
  ARTICLEINGRESSB: 'articleIngressB',
  ARTICLEINGRESSC: 'articleIngressC',
  ARTICLEINGRESSSEOSE: 'articleIngressSEOSE',
  ARTICLEINGRESSSEONO: 'articleIngressSEONO',
  ARTICLEINGRESSSEOFI: 'articleIngressSEOFI',
  ARTICLEINGRESSSEODK: 'articleIngressSEODK',
  INSTAGRAMGALLERY: InstagramGallery,
  HEROBLOCK: HeroBlock,
  HEROITEM: 'heroItem',
  HEROVIDEOBLOCK: HeroVideoBlock,
  INFOBLOCKHTML_NEW_BOTTOM: InfoBlock
};

const Container = styled(MaxWidth)`
  ${theme.above.sm} {
    padding-top: 2rem;
  }
`;

const CategoryWrapper = styled('div')`
  padding-top: 1rem;
`;

const ProductNumber = styled('p')`
  text-transform: uppercase;
  font-size: 12px;
  color: ${theme.colorScheme.mediumgrey};
  letter-spacing: 0.5px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

const ButtonWrapper = styled('div')`
  width: 100%;
  max-width: 16rem;
  margin: 1rem auto;
`;

const WindowedCategory = ({ category, result }) => {
  const { previous, next, products } = useInfinitePagination({
    result,
    query: routeQuery
  });

  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  if (!category) {
    return (
      <CategoryWrapper>
        <CategoryHeaderLoadingState />
      </CategoryWrapper>
    );
  }
  let renderHeader = true;
  if (category.data) {
    if (category.data.items.find(item => item.type === 'CategoryHeader')) {
      renderHeader = false;
    }
  }

  return (
    <CategoryWrapper>
      <>
        {renderHeader && (
          <CategoryHeader
            category={category}
            parents={result?.data?.route?.parents}
          />
        )}
        {category.data && (
          <ContentRenderer
            items={category.data.items}
            components={{
              IMAGEBLOCK: ImageBlock,
              INFOBLOCKHTML_NEW: InfoBlock,
              HELLORETAILPRODUCTLIST: HelloRetailProductList,
              HELLORETAILPRODUCTLISTSMALL: HelloRetailProductListSmall,
              CATEGORYBUTTONS: CategoryButtons,
              CATEGORYBUTTON: 'categoryButton',
              INFOBLOCK: InfoBlock
            }}
          />
        )}
        {(category.products?.totalResults > 0 ||
          category.products?.filters.length > 0) && (
          <Container>
            <div>
              <Filters
                filters={category.products?.filters || []}
                sortOrders={category.products?.sortOrders || []}
                result={result}
              />
            </div>
            {(category.products?.totalResults > 0 ||
              category.products?.filters) && (
              <ProductNumber>
                {t('{totalProducts} Products', {
                  totalProducts: category.products?.totalResults
                })}
              </ProductNumber>
            )}
            {previous.hasProducts && (
              <ButtonWrapper>
                <TrendLink
                  className="secondary"
                  onClick={e => {
                    e.preventDefault();
                    previous.fetchProducts();
                  }}
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...params,
                      page: previous.page
                    })
                  }}
                >
                  {t('Show more')}
                </TrendLink>
              </ButtonWrapper>
            )}
            <ProductGrid
              id="category"
              products={products}
              listName={category.name}
              categoryPath={category.isDynamic ? null : result?.data?.route}
              loading={result.loading}
            />

            {next.hasProducts && (
              <ButtonWrapper>
                <TrendLink
                  className="secondary"
                  onClick={e => {
                    e.preventDefault();
                    next.fetchProducts();
                  }}
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...params,
                      page: next.page
                    })
                  }}
                >
                  {t('Show more')}
                </TrendLink>
              </ButtonWrapper>
            )}
          </Container>
        )}
      </>
      {category.data && (
        <ContentRenderer
          items={category.data.items}
          components={PageComponents}
        />
      )}
    </CategoryWrapper>
  );
};

export default WindowedCategory;
