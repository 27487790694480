import { styled } from 'linaria/react';
import { theme } from '../../components/Theme';
import { toRem } from '../../helpers';
import MaxWidth from '../../components/Layout/MaxWidth';

const ArticlesBlockWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px 10px 10px;
  margin: 0 auto;
  max-width: 80rem;

  ${theme.below.lg} {
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    flex: none;
    flex-direction: column;
    line-height: 1;
  }
`;

const ArticleWrapper = styled('div')`
  align-items: center;
  justify-content: center;
  height: 400px;
  font-size: 1rem;
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: ${theme.colorScheme.white};

  a {
    overflow: hidden;
    text-decoration: none;
    color: inherit;
  }
  a::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
`;

const ArticleTopWrapper = styled('div')`
  align-items: center;
  justify-content: center;
  height: 400px;
  font-size: 1rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: ${theme.colorScheme.white};

  a {
    overflow: hidden;
    text-decoration: none;
    color: inherit;
  }
  a::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
`;

const TextWrapper = styled('div')`
  background: white;
  line-height: 1.35;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const TextTopWrapper = styled('div')`
  background: white;
  line-height: 1.35;
  padding-left: ${toRem(10)};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ArticleTitle = styled('div')`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.2em;
  font-weight: bold;
`;

const ArticleTopTitle = styled('div')`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.2em;
  font-weight: bold;
`;

const ArticleLink = styled('div')`
  margin-top: 20px;
  margin-bottom: 1em;
  a {
    font-size: 0.9em !important;
    padding: 0.2rem 0.4rem !important;
    background-color: #ffffff;
  }

  ${theme.above.lg} {
    margin-bottom: 0.5em;
  }
`;

const ArticleTopLink = styled('div')`
  margin-top: 20px;
  margin-bottom: 0.5em;
  a {
    font-size: 1em !important;
    background-color: #ffffff;
  }
`;

const ArticleTopImageZoom = styled('div')`
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  transition: all ease-in-out 0.25s;
  transform: scale(1);
`;

const ArticleImageZoom = styled('div')`
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  transition: all ease-in-out 0.25s;
  transform: scale(1);
`;

const ArticleText = styled('div')`
  height: 40px;
  line-height: 1.35;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
`;

const ArticleFade = styled('div')`
  position: absolute;
  bottom: 60px;
  display: block;

  width: 100%;
  height: 25px;

  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 100%
  );
`;

const ArticleTopText = styled('div')`
  line-height: 1.35;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const Badge = styled('div')`
  position: absolute;
  width: 80px;
  background-color: ${theme.colorScheme.primary};
  padding: ${toRem(6)} ${toRem(12)} ${toRem(6)} ${toRem(10)};
  color: ${theme.colorScheme.primarytext};
  font-size: ${props => (props.size === 'sm' ? toRem(10) : toRem(12))};
  line-height: ${props => (props.size === 'sm' ? toRem(12) : toRem(14))};
  font-weight: ${theme.fontWeights.medium};
  border-bottom-right-radius: ${toRem(11)};
`;

const BadgeText = styled('span')`
  text-transform: uppercase;
`;

const ArticleItem = styled.div`
  ${theme.below.lg} {
    width: 100%;
    height: 400px;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    flex: none;
    flex-direction: column;
    line-height: 1;
  }
  display: inline-block;
  flex: 1 1;
  height: 100%;
  position: relative;
  :hover ${ArticleImageZoom} {
    transition: all ease-in-out 0.25s;
    transform: scale(1.05);
  }

  ${theme.above.lg} {
    min-height: ${toRem(10)};
    padding: 0.5rem;
    flex-basis: 30%;
    max-width: 33.3333%;

    &:not(:last-of-type):after {
      content: '';
      position: absolute;
      right: 0;
      height: 80%;
      top: 50%;
      opacity: 0.2;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  ${theme.below.lg} {
    &:nth-child(odd):after {
      content: '';
      position: absolute;
      right: 0;
      height: 80%;
      top: 50%;
      opacity: 0.2;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &:first-child:before,
    &:nth-child(2):before {
      content: '';
      position: absolute;
      left: 50%;
      width: 80%;
      bottom: 0;
      opacity: 0.2;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
`;

const ArticleTopImage = styled('div')`
  grid-column: auto / span 2;
  height: 400px;
  padding: 0.5rem;
`;

const ArticleTopItem = styled.div`
  ${theme.below.lg} {
    width: 1000px;
    height: 400px;
    display: block;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    flex: none;
    flex-direction: column;
    line-height: 1;
  }
  width: 100%;
  height: 100%;
  position: relative;

  ${theme.above.lg} {
    min-height: ${toRem(10)};
    &:not(:last-of-type):after {
      content: '';
      position: absolute;
      right: 0;
      height: 80%;
      top: 50%;
      opacity: 0.2;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    :hover ${ArticleTopImageZoom} {
      transition: all ease-in-out 0.25s;
      transform: scale(1.03);
    }
  }
`;

const ArticleTypeButtonsWrapper = styled(MaxWidth)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin-bottom: 0.5em;
  padding: 0px;

  ::-webkit-scrollbar {
    display: none;
  }
  ${theme.below.lg} {
    margin-top: 0px;
    margin-bottom: 1em;
  }
`;

const ArticleTypeButtonLabel = styled.h2`
  font-size: ${toRem(14)};
  font-weight: ${theme.fontWeights.medium};
  line-height: 20px;
  color: ${theme.colorScheme.black};
  list-style: none;

  ${theme.above.lg} {
    font-size: ${toRem(16)};
  }

  &:child {
    text-decoration: none;
  }
`;
// color: #333333;

const ArticleTypeButton = styled.div`
  background-color: rgba(51, 51, 51, 0.1);
  border-width: 0;
  display: inline-block;
  position: relative;
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  text-align: center;
  padding: 10px 12px;
  transition: all 200ms;

  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  ${theme.below.lg} {
    margin-top: 10px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const style = {
  ArticlesBlockWrapper,
  ArticleWrapper,
  ArticleTopWrapper,
  ArticleText,
  ArticleTopText,
  ArticleTitle,
  ArticleTopTitle,
  ArticleLink,
  ArticleTopLink,
  Badge,
  BadgeText,
  ArticleItem,
  ArticleTopItem,
  ArticleTopImageZoom,
  ArticleImageZoom,
  TextWrapper,
  TextTopWrapper,
  ArticleTopImage,
  ArticleTypeButtonsWrapper,
  ArticleTypeButtonLabel,
  ArticleTypeButton,
  ArticleFade
};

export default style;
