import React from 'react';
import { Below } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import Styled from './Style';
import CategoryLink from './CategoryLink';
import useChildren from '../../hooks/useChildren';
import { theme } from '../../components/Theme';

const getAspectRatio = categories => {
  switch (categories.length) {
    case 2:
      return '169:89';
    case 3:
      return '112:89';
    case 4:
      return '333:356';
    default:
      return '112:89';
  }
};

const CategoryBlock = props => {
  const cleanedProps = useChildren(props);
  const categories = cleanedProps['categoryItem'];

  if (!categories) return null;

  const aspectRatio = getAspectRatio(categories);
  const filteredCategories = categories.filter(
    ({ backgroundImage, externalLinkUrl, internalLinkUrl }) =>
      backgroundImage && (internalLinkUrl || externalLinkUrl)
  );
  const catQty = filteredCategories.length ?? 1;
  const imageSizes = [1, 1, 1, 1 / catQty, 1 / catQty, 1440 / catQty];

  return (
    <Styled.CategoryBlockWrapper>
      {filteredCategories.map(category => {
        const title = category.title;
        const textColour = category.textcolour ?? '#ffffff';
        const lineColour = category.linecolour ?? theme.colorScheme.primary;
        return (
          <Styled.CategoryItem
            key={category.key}
            rounded={category.roundedCorners}
          >
            <CategoryLink
              externalLink={category.externalLinkUrl}
              internalLink={category.internalLinkUrl}
              category={category}
            >
              <Below breakpoint="md">
                {matches =>
                  matches ? (
                    <Styled.ImageWrapper className="below-md">
                      <Image
                        sizes={imageSizes}
                        aspect={'1:1'}
                        src={category.backgroundImage}
                        fillAvailableSpace={true}
                      />
                    </Styled.ImageWrapper>
                  ) : (
                    <Styled.ImageWrapper className="above-md">
                      <Image
                        sizes={imageSizes}
                        aspect={aspectRatio}
                        src={category.backgroundImage}
                        fillAvailableSpace={true}
                      />
                    </Styled.ImageWrapper>
                  )
                }
              </Below>
              {title ? (
                <Styled.CategoryTextContent>
                  <Styled.CategoryTitle
                    textColour={textColour}
                    lineColour={lineColour}
                  >
                    {title}
                  </Styled.CategoryTitle>
                </Styled.CategoryTextContent>
              ) : null}
            </CategoryLink>
          </Styled.CategoryItem>
        );
      })}
    </Styled.CategoryBlockWrapper>
  );
};

export default CategoryBlock;
